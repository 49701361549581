import { debounce } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useFetchUserData } from "../hooks/useFetchUserData";
import { userloggedIn } from "../authSlice";
import { useAppDispatch } from "features/common/redux/hooks";
import { useUIBehaviour } from "features/common/providers/UIBehaviourProvider";
import { DialogModalType } from "features/common/components/DialogModal/DialogModal";

export const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const fetchUserData = useFetchUserData(searchParams.get("token"));

  const showError = debounce(() => {
    setIsLoading(false);
    setModalShow({
      type: DialogModalType.Warning,
      content: <span>Algo deu errado!</span>,
      actions: [
        {
          text: 'Retornar ao portal',
          onClick: () => {
            window.location.href = process.env.REACT_APP_Portal_URL ?? "";
          },
          variant: 'success',
        },
      ],
    });
  }, 1000);

  const getUserData = useCallback(async () => {
    let result = await fetchUserData();

    if (result?.hasError) {
      showError();
      return undefined;
    }

    dispatch(userloggedIn(result?.data));
    return result?.data;
  }, [dispatch, fetchUserData, showError]);

  const fetchData = useCallback(async () => {
    if (!searchParams.get("token")) {
      showError();
      return;
    }

    let userData = await getUserData();

    if (userData) navigate("/");
    else showError();
  }, [searchParams, getUserData, navigate, showError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    progress: { setIsLoading },
    dialog: { setModalShow },
  } = useUIBehaviour();

  useEffect(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  return <></>;
};
